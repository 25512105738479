<template>
  <div class="flex flex-column">
    <header-bar
      :subtitle-prop="subtitle"
      ref="header"
      switchlang="switchlang"
    ></header-bar>
    <section class="MainSection">
      <router-view class="view" ref="childrenView"></router-view>
    </section>
    <footer-bar ref="footer"></footer-bar>
  </div>
</template>

<script>
import HeaderBar from './components/HeaderBar.vue';
import FooterBar from './components/FooterBar.vue';

export default {
  components: { HeaderBar, FooterBar },

  data() {
    return {
      subtitle: '',
    };
  },

  mounted() {
    const currentLang = this.$router.history.current.path.split('/')[1];
    this.updatePage(currentLang);
  },

  watch: {
    $route() {
      const currentLang = this.$router.history.current.path.split('/')[1];
      this.updatePage(currentLang);
    },
  },

  methods: {
    scrollReveal: function () {
      // function isScrolledIntoView(elem) {
      //   var docViewTop = $(window).scrollTop();
      //   var docViewBottom = docViewTop + $(window).height();
      //   var elemTop = $(elem).offset().top;
      //   var elemBottom = elemTop + $(elem).height();
      //   return elemBottom <= docViewBottom && elemTop >= docViewTop;
      // }

      function Utils() {}

      Utils.prototype = {
        constructor: Utils,
        isElementInView: function (element, fullyInView) {
          const pageTop = $(window).scrollTop();
          const pageBottom = pageTop + $(window).height();
          const elementTop = $(element).offset().top + $(window).height() * 0.3;
          const elementBottom = elementTop + $(element).height();

          if (fullyInView === true) {
            return pageTop < elementTop && pageBottom > elementBottom;
          } else {
            return elementTop <= pageBottom && elementBottom >= pageTop;
          }
        },
      };

      return new Utils();
    },

    updateSub: function () {
      const attribute = $('#content').attr('attr-sub');
      this.subtitle = attribute;
      // if(typeof attribute != 'undefined'){
      //   $("h2.subtitle").html(attribute + "<span></span>");
      // }
    },

    updatePage: function (lang) {
      const currentPath = this.$router.history.current.path
        .split('/')
        .slice(2)
        .join('/');
      switch (lang) {
        case 'en':
          this.$i18n.locale = 'en';
          this.$router.push({
            path: '/' + this.$i18n.locale + '/' + currentPath,
          });
          $('body').css('word-break', 'keep-all');
          $('body').removeClass();
          $('.lang').removeClass('active');
          $("li[attr-lang='en']").addClass('active');
          break;
        case 'ko':
          this.$i18n.locale = 'ko';
          this.$router.push({
            path: '/' + this.$i18n.locale + '/' + currentPath,
          });
          $('body').css('word-break', 'keep-all');
          $('body').removeClass();
          $('body').addClass('ko');
          $('.lang').removeClass('active');
          $("li[attr-lang='ko']").addClass('active');
          break;
        case 'ja':
          this.$i18n.locale = 'ja';
          this.$router.push({
            path: '/' + this.$i18n.locale + '/' + currentPath,
          });
          $('body').css('word-break', 'initial');
          $('body').removeClass();
          $('body').addClass('ja');
          $('.lang').removeClass('active');
          $("li[attr-lang='ja']").addClass('active');
          break;
      }

      $('.language').toggle();
      $('html').removeClass('mobile-menu');
      $('.mobile-menu').removeClass('active');
      $('header').removeClass('white');
      $('.burger').removeClass('inactive');
      this.$refs.childrenView.isLoading = true;
      setTimeout(() => {
        this.$refs.childrenView.fetchItem(this.$i18n.locale);
        this.updateSub();
        if (this.$refs.childrenView.consultingModalData) {
          this.$refs.childrenView.fetchModalData(this.$i18n.locale);
        }
        this.$refs.footer.updateBlogURL(this.$i18n.locale);
        if (this.$refs.childrenView.videoID) {
          this.$refs.childrenView.updateVideo(this.$i18n.locale);
          this.$refs.childrenView.stopVideo();
        }
      }, 300);

      $('html,body').animate({ scrollTop: 0 }, 0);

      $('.language').parent().find('span').html(lang);
      $('.language').toggle();
    },

    switchlang: function (event) {
      this.updatePage(event.currentTarget.getAttribute('attr-lang'));
    },
  },
};
</script>

<style lang="stylus">
@import url(@/assets/css/custom.css);
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
@import url(@/assets/css/font.css);

[v-cloak] {
  display: none;
}

[v-cloak]::before {
  content: 'loading…';
}

$primary = #5C5F61;

.primary {
  color: $primary;
}

.bg-primary {
  background-color: $primary;
}

.b--primary {
  border-color: $primary;
}

.mw-85 {
  max-width: 85vw;
}

.mw-87 {
  max-width: 87vw;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-y: scroll;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;

  &.mobile-menu {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &.loading {
    height: 100%;
    width: 100%;
    overflow: hidden;

    #content {
      opacity: 1;
    }
  }
}

#content {
  opacity: 1;
  transition: opacity 0.3s linear;
}

.MainSection {
  overflow: hidden;
}

body {
  background: #fff;
  font-family: 'Futura Std', 'Noto Sans KR';
  font-weight: 300;
  font-size: 0.9em;
  height: 100%;
  width: 100%;
  color: #2A3642;
}

a {
  text-decoration: none;
}

ul {
  padding: 1em;
}

ol {
  @extend ul;
}

h1, h2, h3, h4, h5, h6 {
  color: #343434;
  transition: all 0.5s ease-in-out;
}

img {
  display: block;
  width: 100%;
}

button {
  background: transparent;
}

.Container {
  margin: 0 auto;
  max-width: 80vw;
}

.Row {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -0.8;
  margin-bottom: 0.8em;

  &:last-child {
    margin-bottom: 0;
  }
}

.ColumnFull {
  padding: 1em;
  width: 100%;
}

.Btn {
  border-radius: 2em;
  padding: 0.5em 1.5em;
  font-size: 0.9em;
  transition: all 0.5s ease-in-out;
}

.Btn--primary {
  border: 1px solid $primary;
  color: $primary;

  &:hover {
    background: $primary;
    color: #fff;
  }
}

.view {
  transition: opacity 1s ease-in-out;
  flex: 1;
  min-height: 100vh;
}

.fade-enter-active {
  // transition: opacity 1s cubic-bezier(0.22, 0.44, 0, 1)
}

.fade-leave-active {
  @extend .fade-enter-active;
}

.fade-enter {
  opacity: 0;
}

.fade-leave {
  @extend .fade-enter;
}

.spinner {
  background: #fff;
  height: 2px;
  box-shadow: 0px 1px 3px #ccc;
}

/* Make clicks pass-through */
#nprogress {
  position: absolute;
  left: 0;
  z-index: 999999;
  pointer-events: none;
}

#nprogress .bar {
  background: #e23b55;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  // box-shadow: 0 0 10px black, 0 0 5px black;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner, .nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ja br.mobile-only{
    content:" ";
}
.ja br.web-only{
    content: none;
}

@media screen and (max-width: 30rem){
  .ja br.mobile-only{
      content: none;
  }
  .ja br.web-only{
      content: " ";
  }
}

@media only screen and (max-width: 640px) {
  .ColumnHalf {
    width: 100%;
  }
}

@media all and (min-width: 600px) {
  .global-column-padding {
    padding: 1em;
  }

  .ColumnSeventy {
    width: 75%;
    @extend .global-column-padding;
  }

  .ColumnHalf {
    width: 50%;
    @extend .global-column-padding;
  }

  .ColumnThird {
    width: 33.33%;
    @extend .global-column-padding;
  }

  .ColumnQuarter {
    width: 25%;
    @extend .global-column-padding;
  }

  .pg-loading-screen {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;

    &.hidden {
      display: none;
    }

    img {
      height: 50px;
      width: 50px;
    }
  }
}

@media only screen and (max-width: 780px) {
  html {
    min-width: auto;
  }

  .subtitle {
    display: none !important;
  }
}
</style>
